<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Merchandising from "@/services/Merchandising";
import Http from "@/services/Http";
import Swal from "sweetalert2";
import modalAddBrand from '@/components/modals/merchandise/modalAddBrand';
import modalEditMerchandiseBrand from '@/components/modals/merchandise/modalEditMerchandiseBrand.vue';



/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, modalAddBrand, modalEditMerchandiseBrand},
  page: {
    title: "Brand",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      title: "Brands",
      items: [
        {
          text: "Brands",
          href: "/",
        },
      ],
      merchandiseBrand: [],
      filterData: false,
      isBusy: false,
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      brand_id: '',
      selectedBrand: '',
      sortBy: "display_order",
      selectedCategoryId: null,
      sortDesc: true,
      fields: [
        {
          key: "logo_img",
          label: "Logo Image",
        },
        {
          key: "brand_name",
          label: "Brand Name",
        },
        {
          key: "header_image",
          label: "Header Image",
        },
        {
          key: "brand_code",
          label: "Origin Name",
        },
        {
          key: "display_order",
          label: "Display Order",
        },
        "action",
      ],
    };
  },
  middleware: "authentication",
  computed: {

    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.totalRows = this.items.length;
    this.getMerchandiseBrand();
  },


  methods: {

    async getMerchandiseBrand() {
      this.toggleBusy();
      try {
        const { data } = await Merchandising.getMerchandiseBrand();
        this.merchandiseBrand = data.data || [];
        this.tableData = this.merchandiseBrand.data || [];
      } catch (error) {
        this.error = error?.response?.data?.error || "An unexpected error occurred.";
        console.error(error);
      } finally {
        this.toggleBusy();
      }
    },
    removeBrand(brandId) {
      Swal.fire({
        title: "Delete brand?",
        text: "Are you sure you want to delete this brand? This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Delete",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await Merchandising.removeBrand(brandId);

            if (response.data && !response.data.error) {
              this.getMerchandiseBrand(); // Refresh the table
              Swal.fire("Deleted!", "The brand has been successfully deleted.", "success");
            } else {
              Swal.fire("Error!", response.data.error || "Failed to delete the brand.", "error");
            }
          } catch (error) {
            const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
            Swal.fire("Error!", errorMessage, "error");
          }
        }
      });
    },

    refreshTable() { },

    callModalAddBrand(data) {
      this.groupsData = data;
      this.$bvModal.show("add_brand");
    },
    callModalEditBrand(brand) {
      this.selectedBrand = brand;
      this.$bvModal.show('edit_service_ticket');
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
               @click="callModalAddBrand()">
              <i class="mdi mdi-plus me-2"></i>Add Brand
            </a>
            <div class="row mt-4" >
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
            </div>
            <!-- Table -->
            <b-table :busy="isBusy" table-class="table table-centered datatable table-card-list"
                     thead-tr-class="bg-transparent"
                     :items="tableData"
                     :fields="fields" responsive="sm"
                     :per-page="perPage"
                     :current-page="currentPage"
                     :sort-by.sync="sortBy"
                     :sort-asc.sync="sortDesc"

                     :filter-included-fields="filterOn"
                     show-empty
                     empty-text="No Data Found">
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(logo_img)="data">
                <img :src="data.item.logo_img" alt="Logo" class="table-image" />
              </template>

              <!-- Slot for Header Image -->
              <template #cell(header_image)="data">
                <div>
                  <template v-if="data.item.header_image && data.item.header_image !== null">
                    <img
                        :src="data.item.header_image"
                        class="table-image"
                    />
                  </template>
                  <template v-else>
                    <span>No header image for this brand</span>
                  </template>
                </div>
              </template>



              <template v-slot:cell(action)="data">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item">
                    <a href="javascript:void(0);" class="px-2 text-primary" v-b-tooltip.hover title="Edit" @click="callModalEditBrand(data.item)">
                      <i class="uil uil-pen font-size-18"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Delete" @click="removeBrand(data.item.brand_id)">
                      <i class="uil uil-trash-alt font-size-18"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <router-link title="Brand Details" :to="{ path: `/merchandising/brand_details/${data.item.brand_id}`}">
                      <i class="uil uil-eye font-size-18"></i>
                    </router-link>
                  </li>

                </ul>
              </template>

            </b-table>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modalAddBrand @onRefresh="getMerchandiseBrand()" ></modalAddBrand>
    <modalEditMerchandiseBrand :brandDetails="selectedBrand" ref="editModal"  @onRefresh="getMerchandiseBrand()"/>
  </Layout>
</template>

<style>
.sm {
  width: 1rem;
  height: 1rem;
}
.table-image {
  max-width: 50px;
  max-height: 50px;
  object-fit: cover;
  border-radius: 4px;
}
</style>
